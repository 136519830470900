<template>
  <div class="manage-container">
    <Form
      :column="column"
      :query="query"
      @handleCopyViewUrl="handleCopyViewUrl"
      @handleQRDownload="handleQRDownload"
    />
    <el-divider />
    <!-- <div class="manage-title">群功能</div>
    <div class="manage-info">可以选配群聊功能,并进行管理</div>
    <div v-for="(item, idx) in configList" :key="idx" class="manage-item">
      <div class="manage-item-show">
        <el-image class="manage-item-image" :src="item.image" />
        <div class="manage-item-content">
          <div class="manage-item-content-first">{{ item.title }}</div>
          <div class="manage-item-content-second">
            {{ item.desc }}
          </div>
        </div>
        <el-button
          size="medium"
          :type="item.isEnable ? 'danger' : 'primary'"
          @click="handleClickEnable"
        >
          {{ item.isEnable ? '停用' : '开启' }}
        </el-button>
      </div>
      <el-collapse-transition>
        <div v-if="item.isEnable" class="m-t-20">
          <Form :column="column1" :label-width="60" :query="query1" />
        </div>
      </el-collapse-transition>
    </div> -->
    <div class="manage-btn">
      <el-button
        :loading="loading"
        size="medium"
        type="primary"
        @click="onSubmit"
      >
        {{ translateTitle('保 存') }}
      </el-button>
      <el-button :loading="loading" size="medium" @click="cancel">
        {{ translateTitle('取 消') }}
      </el-button>
    </div>
  </div>
</template>

<script>
  import { translateTitle } from '@/utils/i18n'
  import { mapActions } from 'vuex'
  import {
    getActivityManage,
    saveActivityManage,
    getGroupList,
  } from '@/api/operate'
  import Form from '@/components/Form'
  import clip from '@/utils/clipboard'
  export default {
    components: {
      Form,
    },
    data() {
      return {
        loading: false,
        groupList: [{ id: 0, name: '请选择' }],
        query: {
          id: null,
          can_sign_in: null,
          can_chat: null,
          chat_manager: null,
          view_url: null,
          view_qrcode_path: null,
          sign_in_url: null,
          sign_in_qrcode_path: null,
          chat_group_id: null,
          groupname: null,
          is_verify: null,
        },
        query1: {
          chat_group_id: null,
        },
        openRedPacketList: Object.freeze([
          {
            id: 1,
            label: '开启',
          },
          {
            id: -1,
            label: '关闭',
          },
        ]),
      }
    },
    computed: {
      column() {
        return [
          {
            title: '活动功能',
            label: '活动推广',
            prop: 'view_url',
            clickText: '复制',
            clickEventName: 'handleCopyViewUrl',
            tipsBtn: '活动详情二维码下载',
            tipsEventName: 'handleQRDownload',
          },
          {
            title: '现场管理',
            label: '签到',
            prop: 'can_sign_in',
            type: 'radio',
            list: this.openRedPacketList,
            tips: `<p>${this.translateTitle(
              '开启签到后用户可以在现场通过扫码完成签到'
            )}${
              this.query.sign_in_qrcode_path
                ? ' <a target="_blank" href="' +
                  this.query.sign_in_qrcode_path +
                  '">' +
                  `${this.translateTitle('签到二维码下载')}` +
                  '</a>'
                : ''
            }</p>`,
          },
          {
            title: '报名审核',
            label: '审核开关',
            prop: 'is_verify',
            type: 'radio',
            list: this.openRedPacketList,
            tips: '开启审核后用户报名后需后台通过管理员审核才能成功报名',
          },
        ]
      },
      // 配置项
      configList() {
        return [
          {
            image: require('@/assets/images/operate/icon_func_group_chat.png'),
            title: '暂无开启活动群聊',
            desc: '群聊开启后用户报名即可加入群聊，群消息更新会在消息页面显示。',
            isEnable: this.query.can_chat == 1,
          },
        ]
      },
      column1() {
        return [
          {
            label: '群聊',
            prop: 'chat_group_id',
            type: 'select',
            list: this.groupList,
            optionLabel: 'name',
          },
        ]
      },
    },
    created() {
      this.getActivityManage().then(() => {
        this.getGroupList()
      })
    },
    methods: {
      translateTitle,
      ...mapActions({
        delVisitedRoute: 'tabs/delVisitedRoute',
      }),
      // 取消
      async cancel() {
        await this.delVisitedRoute(this.$route.path)
        this.$router.go(-1)
      },
      // 保存
      async onSubmit() {
        if (
          this.query.can_chat == 1 &&
          (!this.query1.chat_group_id || this.query1.chat_group_id == 0)
        ) {
          return this.$message.error('关联群聊不能为空')
        }
        this.loading = true
        let params = JSON.parse(JSON.stringify(this.query))
        params.chat_group_id = this.query1.chat_group_id
        const data = await saveActivityManage(params)
        this.loading = false
        if (data.success) {
          this.$message.success('保存成功')
          this.cancel()
        } else {
          this.$message.error(data.msg)
        }
      },
      // 获取群聊列表
      async getGroupList() {
        const params = {
          groupname: this.query.groupname,
          step: 20,
          page: 1,
        }
        const res = await getGroupList(params)
        if (res.success) {
          this.groupList.concat(res.list)
        }
      },
      // 获取详情
      async getActivityManage() {
        const params = {
          id: this.$route.query.id,
        }
        const res = await getActivityManage(params)
        if (res.success) {
          for (let key of Object.keys(this.query)) {
            if (key == 'chat_group_id') {
              this.query1[key] = res.data[key]
            } else {
              this.query[key] = res.data[key]
            }
          }
        }
      },
      // 开启/停用
      handleClickEnable() {
        this.query.can_chat = this.query.can_chat == 1 ? -1 : 1
      },
      // 复制
      handleCopyViewUrl(val, e) {
        clip(val.view_url, e)
      },
      // 活动详情二维码下载
      handleQRDownload() {
        window.open(this.query.view_qrcode_path)
      },
    },
  }
</script>

<style lang="scss" scoped>
  $base: '.manage';
  #{$base}-container {
    #{$base}-title {
      margin: 15px 0;
      font-size: 16px;
      font-weight: bold;
    }
    #{$base}-info {
      margin-bottom: 20px;
      font-size: 13px;
      color: $base-color-text-secondary;
      span {
        color: $base-color-red;
      }
    }
    #{$base}-item {
      padding: 25px;
      border: 1px solid #dcdfe6;
      &-show {
        display: flex;
        align-items: center;
        width: 100%;
      }
      &-content {
        width: 100%;
      }
      &-image {
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        margin-right: 17px;
      }

      &-content-first {
        font-size: 15px;
        font-weight: bold;
      }

      &-content-second {
        width: 70%;
        margin-top: 5px;
        color: $base-color-text-secondary;
        ::v-deep span {
          color: $base-color-red;
        }
      }
      &-from {
        padding: 10px 10px 0 10px;
      }
    }
    #{$base}-btn {
      margin: 40px 0 80px 85px;
    }
    .m-t-20 {
      margin-top: 20px;
    }
  }
</style>

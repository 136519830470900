var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "manage-container" },
    [
      _c("Form", {
        attrs: { column: _vm.column, query: _vm.query },
        on: {
          handleCopyViewUrl: _vm.handleCopyViewUrl,
          handleQRDownload: _vm.handleQRDownload,
        },
      }),
      _c("el-divider"),
      _c(
        "div",
        { staticClass: "manage-btn" },
        [
          _c(
            "el-button",
            {
              attrs: { loading: _vm.loading, size: "medium", type: "primary" },
              on: { click: _vm.onSubmit },
            },
            [_vm._v(" " + _vm._s(_vm.translateTitle("保 存")) + " ")]
          ),
          _c(
            "el-button",
            {
              attrs: { loading: _vm.loading, size: "medium" },
              on: { click: _vm.cancel },
            },
            [_vm._v(" " + _vm._s(_vm.translateTitle("取 消")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }